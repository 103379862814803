<template>
    <div class="apps" v-if="pods_data_ready">


        <div class="apps__category">

            <div class="apps__category__heading">
                Select Categories:
            </div>

            <div class="apps__category__heading__list">
                <template v-for="category in pod_categoris" :key="category">
                    <button class="btn-category" @click="filterPods(category)">{{ category }}</button>
                </template>
            </div>
        </div>

        <div class="apps__list">
            <template v-for="pod in pod_list_filterd" :key="pod.script">
                <pod-card :pod="pod" />
            </template>
        </div>

    </div>
</template>


<script>
import axios from 'axios';
import PodCard from '../components/PodCard.vue'
import { groupBy } from 'lodash'


export default {
    components: {
        "pod-card": PodCard,
    },

    data() {
        return {
            pod_list: [],
            pod_dict: {},
            pod_list_filterd: [],
            pod_categoris: [],
            pods_data_ready: false,
            url: process.env.VUE_APP_API_URL + "pods/list/",
        }
    },

    methods: {

        async load_pod_list() {
            const response = await axios.get(this.url);
            this.pod_list = response.data;
            this.pod_list_filterd = this.pod_list


            // Categories wise pod list
            this.pod_dict = groupBy(this.pod_list, pod => pod.category_name);
            this.pod_categoris = Object.keys(this.pod_dict)
            this.pod_categoris.unshift("All")


            this.pods_data_ready = true

        },

        filterPods(category) {
            if (category === "All") { this.pod_list_filterd = this.pod_list }
            else { this.pod_list_filterd = this.pod_dict[category] }
            console.log(this.pod_list_filterd)
        }
    },

    async mounted() {
        await this.load_pod_list();
    },

}



</script>



<style lang="scss" scoped>
.apps {
    display: flex;
    flex: 0 0 60%;
    flex-wrap: wrap;
    // max-width: 100%;
    // margin: 
    margin: 0rem auto 2rem 0rem;
    min-height: 90rem;
    background-color: $color-pod-list-robot-bg;
    align-items: flex-start;
    caret-color: transparent;


    &__category {
        display: flex;
        flex: 0 0 100%;
        align-items: center;
        flex-wrap: wrap;

        border-bottom: 1px solid darken($color-highlight, 0%);
        padding: 1rem;
        margin: 1rem;


        &__heading {
            display: flex;
            flex: 0 0 100%;
            justify-content: center;
            font-size: 2.5rem;
            letter-spacing: 2px;
            // font-weight: 400;
            // font-family: $font-blog;

            &__list {
                display: flex;
                flex-wrap: wrap;
                flex: 0 0 100%;
                justify-content: center;

            }
        }

    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;

        gap: 30px 10px;
        justify-content: center;
        align-items: center;
        align-content: center;

    }
}
</style>