<template>
    <div class="about">

        <div class="about__game">
            <Suspense>
                <find-my-resume>
                </find-my-resume>
            </Suspense>
        </div>


        <div class="about__blog">
            <div class="about__blog__content" v-html="content">
            </div>

            <div class="about__blog__section__heading">
                Online Profile:
            </div>

            <div class="about__blog__profile">
                <template v-for="p in profile" :key="p.name">
                    <a :href="p.link" target="_blank"><img :src="p.logo" :alt="p.name"></a>
                </template>
            </div>


            <div class="about__blog__section__heading">
                Documents:
            </div>
            <div class="about__blog__document">
                <template v-for="d in documents" :key="d.name">

                    <a :href="d.file" target="_blank">
                        <button class="btn-category">{{ d.name }}</button>
                    </a>
                </template>
            </div>

            <div class="about__blog__section__heading">
                Technologies Known:
            </div>

            <div class="about__blog__tech">
                <div class="tech-tag tech-tag--app" v-for="t in tech" :key="t">
                    {{ t }}
                </div>
            </div>
        </div>


    </div>
</template>


<script>

// import NatoursFloat from './natours_float/NatoursFloat.vue';
import FindResume from '@/game/FindResume/FindResume.vue';
import axios from 'axios';
export default {
    components: {
        'find-my-resume': FindResume
        // 'natours-float':NatoursFloat


    },
    data() {
        return {
            about_url: process.env.VUE_APP_API_URL + "pods/about/",
            content: null,
            tech: [],
            profile: null,
            documents: [],
        }
    },

    methods: {

        async load_about_data() {
            // console.log("about>>>>>>", this.about_url)
            const response = await axios.get(this.about_url);
            const aboutData = response.data[0];
            // console.log(aboutData)
            this.content = aboutData.content
            this.tech = aboutData.tech.map(t => t.name)
            this.profile = aboutData.profile

            aboutData.documents.forEach(d => {
                if (d.file) {
                    this.documents.push(d);
                }
            });

            console.log(this.documents)
        },
    },

    mounted() {
        this.load_about_data();
    }
}

</script>


<style lang="scss">
.about {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    max-width: 100%;
    background-color: $color-pod-list-robot-bg;

    justify-content: center;
    align-items: center;
    margin: 0rem auto;


    &__blog, &__game {
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;
        justify-content: center;
        @include make_half_full();

    }




    &__blog {
        @include some_mixin();
    }


    
}
</style>