<template>
  <div class="pod-card-wrapper">

    <div class="pod-card">
      <div class="pod-card__text">
        <span>{{ pod.name }}</span>
      </div>
      <img :src="pod.image">
      <button class="btn-pod">Run App</button>
    </div>

    <div class="pod-card-i">
      <router-link :to="{
          name: 'Pod',
          params: { script: `${pod.script}` }
        }">

        <i class="material-icons font-large">
          add_circle
        </i>

      </router-link>

    </div>

  </div>
</template>


<script>
export default {
  props: {
    pod: {
      type: Object,
      required: true,
    },
  },



}
</script>


<style lang="scss" scoped>
.pod-card-wrapper {
  display: flex;
  position: relative;

  width: 25rem;
  height: 33rem;

  &:hover>.pod-card {
    opacity: 0.8;
  }

  &:hover>.pod-card-i {
    opacity: 1;
  }

}


.pod-card-i,
.pod-card {
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  transition: .5s ease;
  align-items: center;
  padding: 2rem 3rem 2rem 3rem;
}


.pod-card-i {

  background-color: rgba($color-highlight, 0.9);
  text-align: center;
  align-content: center;
  justify-content: center;

  
  >a>i {
    font-size: 8rem;
    color: white;
  }

  opacity: 0;
}


.pod-card {
  background-color: $color-pod-list-robot-bg;
  outline: #000000 solid 1px;
  box-shadow: 1px 2px 5px 2px white;
  transition: all 0.2s;

  &__text {
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: #000000;
  }


  >img {
    // width: auto;
    height: 20rem;
  }
  
}



.btn-pod {
  background-color: $color-btn-pod-bg;
  outline: rgb(0, 0, 0) solid 0.3rem;
  box-shadow: -0.4rem 0.4rem 5px 1px black;
  padding: 1rem 2rem;
  color: $color-pod-list-robot-bg;

  font-weight: 600;
  font-size: 2rem;
  border-radius: 1px;
}
</style>

