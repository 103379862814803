<template>
    <div class="home">
        <div class="home__slides" v-if="pods_data_ready">
            <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="auto" :loop="true"
                :spaceBetween="auto" :autoHeight="false" :coverflowEffect="{
                        rotate: 0,
                        stretch: 0,
                        depth: 200,
                        modifier: .8,
                        slideShadows: false,
                    }" :autoplay="{
            delay: 100000,
            disableOnInteraction: false,
        }" :navigation="true" :breakpoints="{

            '320': {
                spaceBetween: 120,
                slidesPerView: 1,
            },


            '480': {
                slidesPerView: 2,
                spaceBetween: 10,
            },


            '600': {
                slidesPerView: 3,
                spaceBetween: 0,
            },

            '800': {
                slidesPerView: 4,
                spaceBetween: 0,
            },

            '1024': {
                slidesPerView: 5,
                spaceBetween: 0,
            },
        }" :modules="modules" class="mySwiper">


                <template v-for="pod in pod_list" :key="pod.script">
                    <swiper-slide>
                        <pod-card :pod="pod" />
                    </swiper-slide>
                </template>
            </swiper>
        </div>

        <div class="home__welcome_text">
            <span>
                <!-- I am a Software developer and data scientist with over 4 years of experience in web application development, AI, 
                and software-related projects. 
                Skilled in Python, Django, Javascript, Vue.js, SQL, Postgresql, Mysql, Pytorch, Tableau, CICD, and more. 
                Seeking to leverage my expertise to create innovative web applications and contribute to the growth 
                of a dynamic organization. -->
                I am a Software developer and data scientist with over 4 years experience in web application, AI and
                software related projects.
                I can make custom personal homepages, database-driven, graphic-intensive web
                portals and tailor-made software for complex team processes.
                Seeking to make many more web apps in this journey.
            </span>
        </div>

    </div>
</template>



<script>
import axios from 'axios';
import PodCard from '../components/PodCard.vue'


import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";
import 'swiper/css/navigation';

import { EffectCoverflow, Autoplay, Navigation } from "swiper";


export default {
    components: {
        "pod-card": PodCard,
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            pod_list: [],
            pod_dict: {},
            pods_data_ready: false,
            url: process.env.VUE_APP_API_URL + "pods/list/",
            modules: [EffectCoverflow, Autoplay, Navigation],
            screenWidth: 0,
            slidesPerView: 1,
        }
    },

    methods: {

        async load_pod_list() {
            const response = await axios.get(this.url);
            this.pod_list = response.data;
            this.pods_data_ready = true
        },



        // onScreenResize() {
        //     window.addEventListener("resize", () => {
        //         this.updateScreenWidth();
        //     });
        // },
        // updateScreenWidth() {
        //     this.screenWidth = window.innerWidth;
        //     // console.log(this.screenWidth, this.slidesPerView)
        // },

    },

    async mounted() {
        await this.load_pod_list();
        // this.updateScreenWidth();
        // this.onScreenResize();
    },




}

</script>


<style lang="scss">
.home {

    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    min-height: 80rem;
    width: 100%;

    $color-home-bg: rgba(0, 0, 0, .92);
    $name-logo: url("../../assets/logo/sag_logo.png");

    background-image: linear-gradient(to top, $color-home-bg, $color-home-bg ), url("../../assets/logo/logov2.png");

    &__logo {
        margin: 5rem auto 2rem auto;
        padding: 3rem;
        font-family: $font-heading;
        font-size: 8rem;
        color: white;
    }

    &__nav {

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem auto;

        &__link {
            color: white;
            padding: 1rem;
            font-size: 3rem;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
        }

    }


    &__slides {
        // flex: 1 1 100%;
        display: flex;
        max-width: 70%;
        margin: 5rem auto;
    }

    &__welcome_text {
        display: flex;
        max-width: 90%;

        margin: 1rem auto;
        padding: 3rem;
        // font-family: $font-heading;
        font-size: 2rem;
        font-family: $font-blog;
        letter-spacing: 1px;
        line-height: 3.5rem;
        color: white;
    }

}

.swiper-wrapper {
    padding: 0rem;
}

.swiper-button-prev,
.swiper-button-next {
    color: $color-btn-pod-bg;
}

.swiper-coverflow {
    padding: 2rem;
}
</style>