import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import App from "./App.vue";

import TheHome from "./pages/TheHome.vue";
import TheAbout from "./pages/TheAbout.vue";
// import TheContact from "./pages/TheContact.vue";
import TheApps from "./pages/TheApps.vue";

import VueGtag from "vue-gtag";

console.warn = () => {};

function lazyLoad(view) {
  return () => import(`./pages/${view}.vue`);
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: TheHome,
      name: "Home",
      meta: {
        title: "Sagar's Portfolio Home Page",
      },
    },
  
    {
      path: "/pods",
      component: TheApps,
      name: "PodList",
      meta: {
        title: "Sagar's Portfolio  Apps",
      },
    },

    {
      path: "/pods/:script",
      component: lazyLoad("TheApp"),
      name: "Pod",
      props: true,
      query: true,

      meta: {
        title: "Sagar's Portfolio App",
      },
    },

    {
      path: "/about",
      component: TheAbout,
      name: "About",
      meta: {
        title: "Sagar's Portfolio About",
      },
    },

    // {
    //   path: "/contact",
    //   redirect: '/pods/send_message',
    //   name: "Contact",
    //   meta: {
    //     title: "Sagar's Portfolio Contact",
    //   },
    // },
  ],
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? "Sagar's Portfolio";
});

const app = createApp(App);

app.use(router);
app.use(VueGtag, {
  config: { id: "G-R2P2NWYW5M" }
})

app.mount("#app");
