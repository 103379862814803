<template>
    <div class="header">
        <div class="header__logo">
            <span>Sagar Rathi</span>
        </div>

        <div class="header__nav">
            <router-link to="/">
                <div class="header__nav__link">
                    Home
                </div>
            </router-link>

            <router-link to="/pods">
                <div class="header__nav__link">
                    Apps
                </div>
            </router-link>

            <router-link to="/about">
                <div class="header__nav__link">
                    About Me
                </div>
            </router-link>

            <router-link to="/pods/send_message">
                <div class="header__nav__link">
                    Contact
                </div>
            </router-link>


        </div>
    </div>

    <router-view> </router-view>

    <div class="footer">
        Copyright © 2022-2027 Sagar Rathi All rights reserved. 
    </div>
</template>


<script>

export default {
    components: {
    }
}

</script>


<style lang="scss">

@import "~/scss/_common.scss";


.header {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 22rem;
    $color-home-bg: rgba(0, 0, 0, .92);
    $name-logo: url("~@/assets/logo/sag_logo.png");

    background-image: linear-gradient(to top, $color-home-bg, $color-home-bg ), url("~@/../assets/logo/logov2.png");
    caret-color: transparent;

    &__logo {
        margin: 5rem auto 2rem auto;
        padding: 2rem;
        font-family: $font-heading;
        font-size: 8rem;
        color: white;
    }

    &__nav {

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem auto;

        &__link {
            color: white;
            padding: 1rem 1rem;
            font-size: 2rem;
            border-top: 1px solid white;
            border-bottom: 1px solid white;

            &:hover{
                background-color: lighten($color-highlight, 20%);
                color: black;   
            }
        }

    }
}

.router-link-active{
    background-color: $color-highlight;   
}

.footer {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    //  background-color: rgba($color-highlight, 0.5);
    min-height: 5rem;
    // background-color: $color-pod-list-robot-bg;
    background-color: lighten($color-highlight, 20%);
    caret-color: transparent;

    //  background-image: linear-gradient(to top, rgba($color-pod-list-robot-bg, 0.95), rgba($color-pod-list-robot-bg, 0.95)),     // url("~@/../assets/logo/logov2.png");

}







</style>