<template>
    <div :id="containerId"></div>
</template>


<script setup>
import { onMounted, onUnmounted } from 'vue'

let about_url = process.env.VUE_APP_API_URL + "pods/about/";

let gameInstance = null;
const containerId = "game-canvas"
const game = await import('./game.js')
onMounted(() => {
    gameInstance = game.launch(containerId, about_url);

})

onUnmounted(() => {
    gameInstance.destroy(false)
})

</script>



<style></style>